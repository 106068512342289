<template>
    <div class="row">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Application Home</h5>
                <p>
                    <img
                        src="img/contact-us-phone.jpg"
                        width="257"
                        height="354"
                        class="rounded float-end"
                        alt="contact Certass"
                    />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <contact-add-panel />
    </div>
</template>
<script lang="js">
//import { mapGetters } from "vuex";
//import store from '@/store'
import ContactAddPanel from '@/components/ContactAddPanel'

export default {
    name: 'Home',
    components: {
       'contact-add-panel': ContactAddPanel
    },
    data: () => {
        return {
        }
    },
    computed: {
        //...mapGetters(['isAuthenticated']),
    },
    methods: {

    }
}
</script>
<style scoped lang="scss"></style>
