<template>
    <div class="row">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Who are you?</h5>
                <Message v-if="showError" severity="error" :closable="false">
                    Some fields are incorrect</Message
                >

                <form @submit.prevent="submit">
                    <div class="p-field">
                        <h5>First Name <field-required-notification /></h5>
                        <InputText
                            type="text"
                            class="form-input"
                            id="firstname"
                            name="firstname"
                            v-model="form.firstname"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="firstname"
                        />
                    </div>

                    <div class="p-field">
                        <h5>Last Name <field-required-notification /></h5>
                        <InputText
                            type="text"
                            class="form-input"
                            id="lastname"
                            name="lastname"
                            v-model="form.lastname"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="lastname"
                        />
                    </div>

                    <div class="p-field">
                        <h5>Telephone No <field-required-notification /></h5>
                        <InputText
                            type="text"
                            class="form-input"
                            id="telephone1"
                            name="telephone1"
                            v-model="form.telephone1"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="telephone1"
                        />
                    </div>

                    <div class="p-field">
                        <h5>Date of Birth <field-required-notification /></h5>
                        <InputMask
                            id="new_dateofbirth"
                            name="new_dateofbirth"
                            v-model="form.new_dateofbirth"
                            mask="99/99/9999"
                            slotChar="dd/mm/yyyy"
                            placeHolder="dd/mm/yyyy"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="new_dateofbirth"
                        />
                    </div>

                    <postcode-lookup
                        @address-selected="postcodeAddressUpdated"
                        @address-cleared="clearAddressFields"
                    />

                    <div class="p-field">
                        <Button
                            type="submit"
                            click="submit"
                            class="btn btn-primary btn-lg form-input"
                            :disabled="!fieldsCompleted || loading"
                        >
                            <span v-if="loading"
                                ><i class="pi pi-spin pi-spinner"></i
                            ></span>
                            <span v-else>Submit</span>
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import { mapActions } from "vuex"
import ValidationText from '@/components/helpers/ValidationText'
import convertUkDateField from '@/services/dateFieldService'
import InputMask from 'primevue/inputmask'

import PostcodeLookup from '@/components/helpers/PostcodeLookup'

export default {
    name: 'ContactAddPanel',
    components: {InputMask, ValidationText, 'postcode-lookup' : PostcodeLookup},
    data: () => {
        return {
            form: {
                'firstname': '',
                'lastname': '',
                'emailaddress1': '',
                'telephone1': '',
                'address1_postalcode': '',
                'address1_line1': '',
                'address1_line2': '',
                'address1_line3': '',
                'address1_city': '',
                'address1_county': '',
                'address1_country': '',
                'cr971_authentificationid': '',
                'new_dateofbirth': '',
                //'new_nationalinsurancenumber':''
            },
            showError: false,
            errors: [],
            loading: false,
        }
    },
    props: {
    },
    computed: {
        getAuthUser() {
            return this.$store.state.auth.user
        },
        fieldsCompleted() {
            if (this.form.firstname && this.form.lastname && this.form.telephone1 && this.form.new_dateofbirth && this.form.address1_postalcode && this.form.address1_line1) {
                return true
            }
            return false
        }
    },
    methods: {
        ...mapActions(['saveContactRecord', 'upgradeAuthUser']),
        async submit() {
            this.loading = true

            //Add any values from the authenticated User
            const user = this.getAuthUser
            this.form['cr971_authentificationid'] = user.id
            this.form['emailaddress1'] = user.username

            const data = {
                'firstname': this.form.firstname,
                'lastname': this.form.lastname,
                'emailaddress1': user.username,
                'telephone1': this.form.telephone1,
                'address1_postalcode': this.form.address1_postalcode,
                'address1_line1': this.form.address1_line1,
                'address1_line2': this.form.address1_line2,
                'address1_line3': this.form.address1_line3,
                'address1_city': this.form.address1_city,
                'address1_county': this.form.address1_county,
                //'address1_country': this.form.address1_country,
                'cr971_authentificationid': user.id,
                'new_dateofbirth': convertUkDateField(this.form.new_dateofbirth),
                //'new_nationalinsurancenumber':''
            }

            try {
                this.showError = false

                const profile = await this.saveContactRecord(data)
                await this.upgradeAuthUser(profile)
                this.$router.push({ name: 'HomeUser'})
            } catch (error) {
                this.showError = true
                if (error?.response?.status && error.response.status == 422) {
                    this.errors = error.response.data.errors
                }
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        postcodeAddressUpdated (addresses) {
            this.form['address1_postalcode'] = addresses.postcode ||''
            this.form['address1_line1'] = addresses.address1 || ''
            this.form['address1_line2'] = addresses.address2 || ''
            this.form['address1_line3'] = addresses.locality || ''
            this.form['address1_city'] = addresses.city || ''
            this.form['address1_county'] = addresses.county || ''
        },
        clearAddressFields () {
            this.form['address1_postalcode'] = ''
            this.form['address1_line1'] = ''
            this.form['address1_line2'] = ''
            this.form['address1_line3'] = ''
            this.form['address1_city'] = ''
            this.form['address1_county'] = ''
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-input {
    width: 100%;
}
.p-field {
    display: block;
    margin-top: 20px;
}
</style>
