/*
Convert a UK formated date field DD/MM/YYYY to YYYY-MM-dd
*/
export default function convertUkDateField(dateString: string) {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/
    if (!regex.test(dateString)) return ''
    const parts = dateString.split('/')
    return `${parts[2]}-${parts[1]}-${parts[0]}`
}
